import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,Picker,Button,Switch
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';
import DateTimePicker from 'react-datetime-picker';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'
import axios from 'axios';
import { api } from '../../../assets/assets';

const EditSubscribe =({navigation,route})=>{
    const { item} = route.params;

  const handelDate = (date) => {
     const year = date.getFullYear();
     const month = String(date.getMonth() + 1).padStart(2, "0");
     const day = String(date.getDate()).padStart(2, "0");
     return(`${year}-${month}-${day}`)
   }
    const nav=useSelector(state =>state.navbar.nav2Active);

    
    const [error, setError] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [userId, setUserId] = useState(item.userid);
    const [name,setName]=useState(item.name)
    const [selectedUser, setSelectedUser] = useState(null);
    const [packageId, setPackageId] = useState(item.package_id);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [snacks, setSnacks] = useState(item.snacks);
    const [protein, setProtein] = useState(item.protein);
    const [meals, setMeals] = useState(item.meals);
    const [carbs, setCarbs] = useState(item.carbs);
    const [calories, setCalories] = useState(item.Calories);
    const [amount, setAmount] = useState(item.amount);
    const [trackId, setTrackId] = useState(item.trackid);
    const [paid, setPaid] = useState(item.paid);
    const [paymentId, setPaymentId] = useState(item.payment_id);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [deliveryTimeId, setDeliveryTimeId] = useState(item.deleviryTime);
    const [selectedDeliveryTime, setSelectedDeliveryTime] = useState(null);
    const [notes, setNotes] = useState(item.notes);
    const [startDate, setStartDate] = useState(new Date(item.startDate));
    const [endDate, setEndDate] = useState(new Date(item.endDate));
    const [pauseStartDate, setPauseStartDate] = useState(item.puseStartDate!=null?
        new Date(item.puseStartDate):item.puseStartDate
        );
    const [pauseEndDate, setPauseEndDate] = useState(item.puseEndDate!=null?
        new Date(item.puseEndDate):item.puseEndDate);
    const[discountCode,setDiscountCode]=useState(item.code);
  
    // Define the user and package data
    const [users,setusers] = useState([{ "id": 1, "name": "loading...", "phone": "0" }]);
  
    const [packages,setpackages] =useState([    { "id": 1, "packageNameAR": "loading..." }]);
  
    const payments = [    { "id": 1, "paymentname": "cash" } ,{ "id": 2, "paymentname": "myfatoorah" }  ];
  
    const deliveryTimes = [    { "id": 1, "time": "5 am to 8 am" }  ];
  
    // Modal state and functions
    const [modalVisible, setModalVisible] = useState(false);
    const [searchPhone, setSearchPhone] = useState('');
  
    const handleUserSelect = () => {
      setSelectedUser(users.find(user => user.id === userId));
      setModalVisible(false);
    };
  
    const handleSearchPhone = (text) => {
      setSearchPhone(text);
    };

    const [pause,setpuse]=useState("null")

  
    const filteredUsers = users.filter(user => user.phone.includes(searchPhone));
  
    // Package picker items
    const packagePickerItems = packages.map((pkg) => (
      <Picker.Item key={pkg.id} label={pkg.packageNameAR} value={pkg.id} />
    ));
  
    // Payment picker items
    const paymentPickerItems = payments.map((payment) => (
      <Picker.Item key={payment.id} label={payment.paymentname} value={payment.id} />
    ));
  
    // Delivery time picker items
    const deliveryTimePickerItems = deliveryTimes.map((time) => (
      <Picker.Item key={time.id} label={time.time} value={time.id} />
    ));

    const [deliveryTime, setDeliveryTime] = useState(null);
const [pauseStartDateSelected, setPauseStartDateSelected] = useState(false);
const [pauseEndDateSelected, setPauseEndDateSelected] = useState(false);


const [days, setDays] = useState({
       
  Saturday: item.Saturday,
  Sunday: item.Sunday,
   Monday: item.Monday,
  Tuesday: item.Tuesday,
  Wednesday: item.Wednesday,
  Thursday: item.Thursday,
  Friday: item.Friday
});

const [daysInWeek, setdaysInWeek] = useState(item.daysInWeek);
const [subscribeDays, setsubscribeDays] = useState(item.subscribeDays);

const [selectedDays, setSelectedDays] = useState([]);
const [errorMessage, setErrorMessage] = useState('');

const handleDaySelection = (day) => {
  if (selectedDays.includes(day)) {
    setSelectedDays(selectedDays.filter((selectedDay) => selectedDay !== day));
    setDays({ ...days, [day]: 'yes' });
  } else {
      setSelectedDays([...selectedDays, day]);
      setDays({ ...days, [day]: 'no' });
 
  }
};
const handleSubmit = () => {
  if (
    userId !== '' &&
    packageId !== '' &&
    discountCode !== '' &&
    snacks !== '' &&
    meals !== '' &&
    protein !== '' &&
    carbs !== '' &&
    amount !== '' &&
    paid !== '' &&
    paymentId !== '' &&
    startDate !== '' && endDate !== '' && deliveryTimeId !== '' && notes !== '' 
    ){
      let data={
        user_id:userId,
        package_id:packageId,
        snacks:snacks,
        protein:protein,
        meals:meals,
        carbs:carbs,
        Calories:calories,
        code:discountCode,
        amount:amount,
        trackid:trackId,
        paid:paid,
        payment_id:paymentId,
        deleviryTime:deliveryTimeId,
        notes:notes,
        startDate:handelDate(startDate),
        endDate:handelDate(endDate),
        puseStartDate:pause=="not null"
        ?handelDate(pauseStartDate):pause,

        puseEndDate:pause=="not null"
        ?handelDate(pauseEndDate):pause,


        daysInWeek:daysInWeek,
        subscribeDays:subscribeDays,
        Monday:days.Monday,
        Tuesday:days.Tuesday,
        Wednesday:days.Wednesday,
        Thursday:days.Thursday,
        Friday:days.Friday,
        Saturday:days.Saturday,
        Sunday:days.Sunday
      }
      console.log(data)

      axios({
        method: "put",
        url: api+"Subscription/"+item.id,
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
        data:data
    })
    .then((response) => {
      alert("done")
setError("done")
         console.log(response.data);
    })
    .catch((error) => {
        console.log(error);
    });
  
    }
    else{
      alert('Invalid user:')
    }
  // code to handle form submission
};
useEffect(() => {
    item.puseStartDate!=null?
    setPauseStartDateSelected('set'):setPauseStartDateSelected(null)

    item.puseEndDate!=null?
    setPauseEndDateSelected('set'):setPauseEndDateSelected(null)
  axios({
    method: "get",
    url: api+"User",
    headers: { 
        'Accept': 'application/json',
        'Authorization': 'Bearer '
    }
})
.then((response) => {
     console.log(response.data);
     setusers(response.data);
     setUserId(item.userid)
})
.catch((error) => {
    console.log(error);
});
axios({
  method: "get",
  url: api+"Package",
  headers: { 
      'Accept': 'application/json',
      'Authorization': 'Bearer '
  }
})
.then((response) => {
   console.log(response.data);
   setpackages(response.data);
})
.catch((error) => {
  console.log(error);
});
}, [])
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={2}/>
      <NavBar3 navigation={navigation} nav2active={2}/>



        
      <View style={styles.container2}>
        <Text >Edit subscribe page</Text>

         

{/* genral info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>genral info</Text>


 {/* input user name */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>User: {name}</Text>
{/* <Picker
        selectedValue={userId}
        onValueChange={(value) => setUserId(value)}
        style={styles.TextInput}

      >
       
        {users.map((user) => (
<Picker.Item key={user.id} label={user.name +" " +user.phone} value={user.id} />
))}
        {
}
</Picker> */}
</View>
 {/* end of input user name */}

  {/* input Package name */}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Package:</Text>
<Picker
selectedValue={packageId}
onValueChange={(value) => setPackageId(value)}
style={styles.TextInput}

>
<Picker.Item label="Select package" value={null} />
{packages.map((pkg) => (
<Picker.Item key={pkg.id} label={pkg.packageNameAR} value={pkg.id} />
))}
</Picker>
</View>
  {/* end of input Package name */}

</View>
  {/* end of genral info */}

    {/* days of subscribe*/}

    <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>days </Text>
<Text >days اختر ايام العطل فقط</Text>


<View style={{flexDirection:"row"}}>

{Object.keys(days).map((day) => (
        <TouchableOpacity
          key={day}
          onPress={() => handleDaySelection(day)}
          style={{ flexDirection: 'row', alignItems: 'center' ,margin:10}}
        >
          <View
            style={{
              width: 24,
              height: 24,
              borderRadius: 12,
              borderWidth: 2,
              marginRight: 8,
              backgroundColor: days[day] === 'no' ? "black" : 'transparent',
              borderColor: colors.darkburble,
            }}
          />
                 <Text style={styles.daysBtnText}> 
                     {day} -
                     {
                     day=="Saturday"?"السبت":
                     day=="Sunday"?"الأحد":
                     day=="Monday"?"الأثنين":
                     day=="Tuesday"?"الثلاثاء":
                     day=="Wednesday"?"الاربعاء":
                     day=="Thursday"?"الخميس":
                     day=="Friday"?"الجمعة":null
                     
                     
                     }
                  </Text>
        </TouchableOpacity>
      ))}
      </View>
      <View style={{flexDirection:"row"}}>
          {/* input daysInWeek  */}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>days In Week :</Text>
<TextInput
value={daysInWeek}
onChangeText={(text) => setdaysInWeek(text)}
style={styles.TextInput}
/>
</View>
  {/* end of daysInWeek  */}

         {/* input subscribeDays  */}
         <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>total subscribe Days :</Text>
<TextInput
value={subscribeDays}
onChangeText={(text) => setsubscribeDays(text)}
style={styles.TextInput}
/>
</View>
  {/* end of daysInWeek  */}
</View>
</View>
  {/* end days of subscribe*/}


{/* Discount Code  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Discount Code</Text>

  {/* input Discount Code */}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Discount Code :</Text>
<TextInput
value={discountCode}
onChangeText={(text) => setDiscountCode(text)}
style={styles.TextInput}
/>
</View>
  {/* end of input protien  */}

</View>
{/* Discount Code  */}

{/* meals info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>meals info</Text>
      {/* 4 row input */}
<View style={{flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>

{/* input Snacks */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Snacks:</Text>
<Picker
selectedValue={snacks}
onValueChange={(value) => setSnacks(value)}
style={styles.TextInput}

>
{[...Array(6).keys()].map((snack) => (
<Picker.Item key={snack} label={String(snack )} value={snack } />
))}
</Picker>

</View>
  {/* end of input Snacks  */}

    {/* input meals */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>meals:</Text>
<Picker
selectedValue={meals}
onValueChange={(value) => setMeals(value)}
style={styles.TextInput}
>
{[...Array(6).keys()].map((meal) => (
<Picker.Item key={meal} label={String(meal + 1)} value={meal + 1} />
))}
</Picker>
</View>
    {/* end of input meals */}


  {/* input Protein */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Protein:</Text>
<TextInput
keyboardType="numeric"
value={protein}
onChangeText={(text) => setProtein(text)}
style={styles.TextInput}
/>
</View>
  {/* end of input protien  */}

    {/* input Carbs */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Carbs:</Text>
<TextInput
keyboardType="numeric"
value={carbs}
onChangeText={(text) => setCarbs(text)}
style={styles.TextInput}
/>
</View>
  {/* end of input Carbs  */}

</View>      {/* end of 4 row  */}


   {/* input Calories */}
   <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Calories:</Text>
<TextInput
keyboardType="numeric"
value={calories}
onChangeText={(text) => setCalories(text)}
style={styles.TextInput}
/>
</View>
  {/* end of input Calories  */}


</View>
{/*end of meals info  */}


{/* payment info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>payment info</Text>


      {/* 4 row input */}
<View style={{flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>


{/* input Amount */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Amount:</Text>
<TextInput
keyboardType="numeric"
value={amount}
onChangeText={(text) => setAmount(text)}
style={styles.TextInput}
/>
</View>
{/* end of input Amount */}


{/* input Track */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Track ID:</Text>
<TextInput
keyboardType="numeric"
value={trackId}
style={styles.TextInput}

/>
</View>
{/* end of input Track */}


{/* input Paid */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Paid:</Text>
<Picker
selectedValue={paid}
onValueChange={(value) => setPaid(value)}
style={styles.TextInput}
>
<Picker.Item label="Select option" value={null} />
<Picker.Item label="Yes" value="yes" />
<Picker.Item label="No" value="no" />
</Picker>
</View>
{/* end of input Paid */}


{/* input Payment method */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Payment method:</Text>
<Picker
selectedValue={paymentId}
onValueChange={(value) => setPaymentId(value)}
style={styles.TextInput}

>
<Picker.Item label="Select payment" value={null} />
{payments.map((payment) => (
<Picker.Item key={payment.id} label={payment.paymentname} value={payment.id} />
))}
</Picker>
</View>
{/* end of input Payment method */}



</View> {/* end of 4 row  */}
</View>
{/* end of payment info  */}

  

  {/* dates info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>dates info</Text>



      {/* 2 row input */}
      <View style={styles.ViewInput2Row}>
      <View style={{width:350}}><Text style={styles.inputHeaderText}>Start Date:</Text></View>

            <View style={{width:350}}><Text style={styles.inputHeaderText}>End Date:</Text></View>

      </View>

<View style={styles.ViewInput2Row}>

  {/* input Start Date */}
<View style={styles.inputView}>
<View style={{width:350,height:350}}>

<DateTimePicker
    value={startDate}
    onChange={(date) => setStartDate(date)}
    format="dd/MM/yyyy HH:mm"
    //minDate={new Date()}
    style={styles.TextInput}

    />
</View></View>
  {/* end of input Start Date */}


   {/* input End Date */}
<View style={styles.inputView}>
<View style={{width:200,height:350}}>
<DateTimePicker
        value={endDate}
        onChange={(date) => setEndDate(date)}
        format="dd/MM/yyyy HH:mm"
        minDate={startDate || new Date()}
        />
</View>

</View>
  {/* end of input End Date */}






</View>
      {/* end of 2 row input */}

         {/* 2 row input */}
<View style={styles.ViewInput2Row}>
     {/* input Pause Start Date */}
     <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Pause Start Date:</Text>
<View style={{ flexDirection: "row" }}>
        {/* <Picker
          selectedValue={pauseStartDateSelected}
          onValueChange={(value) =>{ 

        value=='null'?setpuse("null"):setpuse("not null")
        value=='null'?setpuse("null"):setPauseStartDateSelected(value)

          }}
          style={styles.TextInput}
        >

          <Picker.Item label="No Pause" value={"null"} />
          <Picker.Item label="Set Pause Start Date" value={"set"} />
        </Picker> */}
        {pauseStartDateSelected === "set" && (
          <DateTimePicker
            value={pauseStartDate}
            // onChange={(date) => setPauseStartDate(date)}
            format="dd/MM/yyyy HH:mm"
            minDate={startDate || new Date()}
            maxDate={endDate}
            style={styles.TextInput}
          />
        )}
      </View>
</View>
     {/*end of  input Pause Start Date */}


      {/* input Pause End Date */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Pause End Date:</Text>
<View >
        {/* <Picker
          selectedValue={pauseEndDateSelected}
          onValueChange={(value) => {
    
            value=='null'?setpuse("null"):setpuse("not null")
            value=='null'?setpuse("null"):setPauseEndDateSelected(value)


        }}
          style={styles.TextInput}

        >

          <Picker.Item label="No Pause" value={"null"} />
          <Picker.Item label="Set Pause End Date" value={"set"}
          />
        </Picker> */}
        {pauseEndDateSelected === "set" && (
          <DateTimePicker
            value={pauseEndDate}
            // onChange={(date) => setPauseEndDate(date)}
            format="dd/MM/yyyy HH:mm"
            minDate={pauseStartDate || startDate}
            maxDate={endDate}
            style={datePickerStyles.container}

          />
          
        )}
      </View>
</View>
     {/*end of  input Pause End Date */}
</View>
   {/* end of 2 row input */}

</View>
  {/* end of dates info  */}




  {/*Delivery time  */}
  <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Delivery time</Text>

   {/* input Delivery time */}
   <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Delivery time:</Text>
<Picker
    selectedValue={deliveryTimeId}
    onValueChange={(value) => setDeliveryTimeId(value)}
    style={styles.TextInput}

  >
    <Picker.Item label="Select delivery time" value={null} />
    {deliveryTimes.map((time) => (
      <Picker.Item label={time.time} value={time.id} key={time.id} />
    ))}
  </Picker>
</View>
  {/*end of */}

</View>
  {/* end of Delivery time  */}


  {/* notes  */}
  <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>notes</Text>
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>notes :</Text>
<TextInput
    value={notes}
    onChangeText={(text) => setNotes(text)}
    style={styles.TextInput}
  />
  </View>

</View>
  {/* notes */}





    
  
    
  

 



 
 











            

         






        
        </View>
        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}   
                onPress={()=>handleSubmit()}     
>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                    <Text style={styles.saveBtnText}>update</Text>
                </TouchableOpacity>

            </View>

           
</View>
    )
}

export default EditSubscribe;
