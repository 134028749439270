import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,  Picker,Button

    } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'

import axios from 'axios';
import { api } from '../../../assets/assets';
import InprogressBtn from './InprogressBtn';
import useTable from "../../../assets/hooks/useTable";
import PrintBox from './PrintBox';




const MealsDetails =({navigation,route})=>{
  const dispatch = useDispatch();
  const lang=useSelector(state =>state.navbar.language);

  function sortArrayByHeader(header, array) {
    const sortedArray = [...array].sort((a, b) => {
      // compare the values of the two objects based on the header
      if (a[header] < b[header]) {
        return -1;
      }
      if (a[header] > b[header]) {
        return 1;
      }
      return 0;
    });
    return sortedArray;
  }
  const nav=useSelector(state =>state.navbar.nav2Active);
  const { item,datestring} = route.params;

  const remaining=useSelector(state =>state.remainingReducer.remaining);
  const [subscriptions,setsubscriptions]=useState(item.subscriptions)
    function countCarbProtein(array) {
      let counts = {};
      for (let i = 0; i < array.length; i++) {
        let item = array[i];
        let key = "carbs " + item.carbs + " - protein " + item.protein;
        counts[key] = (counts[key] || 0) + 1;
      }
      let total=0;
      return Object.entries(counts).map(([key, count]) => {
        const [carbs, protein] = key.split(' - ');
        return { carb: parseInt(carbs.split(' ')[1]), protein: parseInt(protein.split(' ')[1]), count };
      });
    }
      // function countCarbProtein(array) {
      //   let counts = [];
      //   for (let i = 0; i < array.length; i++) {
      //     let item = array[i];
      //     let key = "carbs " + item.carbs + " - protein" + item.protein;
         
      //     let countArray={'carbs':item.carbs,'protein':item.protein,'counts':(counts[key] || 0) + 1}
      //     counts[i] = countArray;
      //   }
      //   return counts;
      // }
      let array = [
        {userid:1,carb : 100 , protien :110},
        {userid:2,carb : 100 , protien :114},
        {userid:3,carb : 100 , protien :114},
        {userid:3,carb : 100 , protien :110},
        {userid:4,carb : 100 , protien :112},
        {userid:5,carb : 70 , protien :110},
      ];
      
      const [counts,setCounts] = useState(countCarbProtein(item.subscriptions));
      console.log(counts);

      function filterCarbProtein(array, carbs, protein) {
        let filtered = array.filter(item => item.carbs === carbs && item.protein === protein);
        return filtered;
      }
      console.log(filterCarbProtein(item.subscriptions,100,100));
      useEffect(() => {
        dispatch({
          type : "setRemaining",
          payload:item.count
      })
      }, [])

    
     
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={2}/>
      <NavBar3 navigation={navigation} nav2active={2}/>    

      <View style={styles.container2}>
        <Text >meals details</Text>

          {/* all text input */}

          <TouchableOpacity>
            <Text>print pdf</Text>
          </TouchableOpacity>

          {
            subscriptions&&
           <PrintBox 
           subscriptions={subscriptions}
          datestring={datestring} 
           mealnamear={item.nameAR}
           mealnameen={item.nameEN}
           />
          }

  {/*Table count  */}
  <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>count</Text>

{/* table View */}
<View style={{marginVertical:15,marginHorizontal:10}}>
  {/* TableHeader */}
<View style={styles.tableHeaderView}>
<Text  style={styles.cellHeader}>
remaining
          </Text>
<Text  style={styles.cellHeader}>
            count
          </Text>
        <Text  style={styles.cellHeader}>
            meal
          </Text>
          
      </View>
        {/* end of TableHeader */}

        
   <View
   style={styles.row}>
     <Text style={styles.cell}>
            {remaining}
            </Text>
            <Text style={styles.cell}>
            {item.count}
            </Text>
            <Text style={styles.cell}>
            {lang=="ar"?item.nameAR:item.nameEN}
            </Text>
            </View>
</View>
{/* end table View */}
</View>
 {/*end Table count*/}


   {/*Table components  */}
   <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>count P and C</Text>

{/* table View */}
<View style={{marginVertical:15,marginHorizontal:10}}>
  {/* TableHeader */}
<View style={styles.tableHeaderView}>

        <Text  style={styles.cellHeader}>
           per Carb and protien 
          </Text>
          
      </View>
        {/* end of TableHeader */}

        {
   counts&&
   counts.map((count,index)=>
   <View
   style={styles.row}>


   <Text style={styles.cell}> 
   protein : {"("} {count.protein} {")"}
    carb : {"("} {count.carb} {") "}
    count :  {"("}{count.count} {")"} 
   total protein :{"("}{Number(count.protein)*Number(count.count)}{")"} |
    total carb :{"("}{Number(count.carb)*Number(count.count)}{")"}</Text>


   </View>
  
   )}
</View>
{/* end table View */}
</View>
 {/*end Table components*/}







 {/*Table components  */}
 <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>components</Text>

{/* table View */}
<View style={{marginVertical:15,marginHorizontal:10}}>
  {/* TableHeader */}
<View style={styles.tableHeaderView}>
<Text  style={styles.cellHeader}>
          quantity
          </Text>
        <Text  style={styles.cellHeader}>
            component 
          </Text>
          
      </View>
        {/* end of TableHeader */}

        {
   item&&
   item.components.map((component,index)=>
   <View
   style={styles.row}>
    <Text style={styles.cell}>{Number(component.quantity)*Number(item.count)} | {component.unit_name}</Text>
   <Text style={styles.cell}>{component.component_nameAR} {'('} {component.quantity} {')'}</Text>

   </View>
  
   )}
</View>
{/* end table View */}
</View>
 {/*end Table components*/}

 


           {/*notes  */}
   <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>notes</Text>
{/* table View */}
<View style={{marginVertical:15,marginHorizontal:10}}>
  {/* TableHeader */}
<View style={styles.tableHeaderView}>
<TouchableOpacity>
<Text  style={styles.cellHeader}>
            statues
          </Text>
</TouchableOpacity>

          <TouchableOpacity onPress={()=>setsubscriptions(sortArrayByHeader("protein",item.subscriptions))}>
          <Text  style={styles.cellHeader}>
            protien
          </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={()=>setsubscriptions(sortArrayByHeader("carbs",item.subscriptions))}>

<Text  style={styles.cellHeader}>
            carb
          </Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={()=>setsubscriptions(sortArrayByHeader("notes",item.subscriptions))}>
          <Text  style={styles.cellHeader}>
            notes
          </Text>
          </TouchableOpacity>
          <TouchableOpacity>
          <Text  style={styles.cellHeader} onPress={()=>setsubscriptions(sortArrayByHeader("id",item.subscriptions))}>
            id
          </Text>
          </TouchableOpacity>
     
          
      </View>
        {/* end of TableHeader */}

   
              {
   subscriptions&&
   subscriptions.map((subscription,index)=>
   <View
   style={styles.row}>



               <TouchableOpacity 
               ><Text style={styles.cell}><InprogressBtn/></Text> </TouchableOpacity>

<TouchableOpacity>      <Text style={styles.cell}>{subscription.protein}</Text></TouchableOpacity> 

<TouchableOpacity>     <Text style={styles.cell}>{subscription.carbs}</Text></TouchableOpacity> 
<TouchableOpacity>   <Text style={styles.cell}>{subscription.notes}</Text></TouchableOpacity> 
<TouchableOpacity>   <Text style={styles.cell}>{subscription.subscribe_id}</Text></TouchableOpacity> 



   </View>
  
   )}
    
            
           
</View>
{/* end table View */}
</View>
 {/*end of  notes */}
 

      
    



        
        </View>

           
</View>
    )
}

export default MealsDetails;