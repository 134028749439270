 //export const companyName ={id : 1,nameAr:" نظام كالوري كلوب", nameEN:"Calorie Club System"}
 export const companyName ={id : 1,nameAr:" نظام مطعم بومب ", nameEN:"bomb restuarnt System"}
export const version="1.4.4";

// export const api='http://127.0.0.1:8000/api/';
export const api='https://drcheflaravel.com/api/';






export const imageAPI = [];

for (let i = 1; i <= 297; i++) {
  imageAPI.push({
    id: i,
    imgurl: "https://drcheflaravel.com/public/images/productimgs/" + i + ".png"
  });
}






