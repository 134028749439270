import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,StyleSheet,Modal,ScrollView,Picker
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';
import dateicon from '../../../assets/iconpng/newicons/date.png'

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'
import {searchPicker} from '../../../assets/stylesDark/searchPicker'
import axios from 'axios';
import DateTimePicker from 'react-datetime-picker';
import useaxios from '../../../assets/hooks/useaxios';
import { api } from '../../../assets/assets';


const AddMealsNotSelect =({navigation})=>{
    const nav=useSelector(state =>state.navbar.nav2Active);
    const lang=useSelector(state =>state.navbar.language);


  const [loading ,setloading]=useState(0);
    const [error,seterror]=useState()

    // select date 
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [datestring,setdatestring]= useState()
  
    const today = new Date();

    const [year, setYear] = useState(today.getFullYear());
    const [month, setMonth] = useState(today.getMonth()+1);
    const [day, setDay] = useState(today.getDate());
  
    const years = Array.from({length: 10}, (v, i) => 2023 + i);
    const months = Array.from({length: 12}, (v, i) => i + 1);
    const days = Array.from({length: 31}, (v, i=0) => i + 1);




    const handleDayChange=(day)=>{
      axios({
        method: 'post',
        url: api+'searchNotselecttablesByDate',
        headers: { 
          'Accept': 'application/json',
          'Authorization': 'Bearer '
        },
        data:{
         
          date:year+"-"+month+"-"+day
        
        }
      })
      .then(response => {
       setItems(response.data)
       setSnacks(response.data.filter(item =>  item.type === 'snack'))
       setFilteredMeal(response.data)
       
       console.log(response.data)
      })
      .catch(error => {
        seterror(error.message);

      });

    }
  
    const dateString = selectedDate ? selectedDate.toISOString().slice(0, 10) : '';
    // end select date 



    const returnarray=(array)=>{
      let snacks = [];

// Loop through the items array and add any items with category_chef_id equal to 3 to the snacks array
for (let i = 0; i < array.length; i++) {
    snacks.push(items[i]);
  
}
return snacks

    }



    //categorychef
      const [categories,setcategories] = useState([])
      const [searchQuery, setSearchQuery] = React.useState('');
      const [selectedCategory, setSelectedCategory] = React.useState('');
      const [selectedcategoryName, setselectedcategoryName] = React.useState('');
      const[type,setType]=useState('')

      
      const [isPickerVisible, setPickerVisible] = React.useState(false);
      const [filteredCategories, setFilteredCategories] = React.useState(categories);
    
      const handleSearch = (query) => {
        const filtered = categories.filter((category) =>
          category.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCategories(filtered);
        setSearchQuery(query);
      };
    
      const handleValueChange = (value,name,type) => {
        setSelectedCategory(value);
        setPickerVisible(false);
        setselectedcategoryName(name)
        setType(type)
      };
      // end of categorychef


      // meals 
      const [meals,setmeals] = useState([])
      const [mealsSearchQuery, setMealsSearchQuery] = useState('');
      const [selectedMealId, setSelectedMealId] = useState('');
      const [selectedMealName, setSelectedMealName] = useState('');

      const [isMealPickerVisible, setMealPickerVisible] = useState(false);
      const [filteredMeal, setFilteredMeal] = React.useState(meals);

      const handleSearchmeal= (query) => {
        const filtered = meals.filter((meal) =>
          meal.itemNameAR.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredMeal(filtered);
        setMealsSearchQuery(query);
      };
    
      const handleMealValueChange = (value,name) => {
        setSelectedMealId(value);
        setMealPickerVisible(false);
        setSelectedMealName(name)
      };
      // end of meals



      // array of chef meals
      const [items, setItems] = React.useState([]);
      const [snacks,setSnacks] = useState(items.filter(item =>  item.type === 'snack'));

      const addItemToArray = (array, selectedDate
        , selectedCategory,selectedcategoryName
        ,selectedMealId,selectedMealName,type
        ) => {


        const newItem = { 
          date:selectedDate,
          item_id:selectedMealId,
          itemNameAR:selectedMealName,
          category_chef_id:selectedCategory ,
          categoryNameAR:selectedcategoryName,
          type:type


          
        };
        const newArray = [...array, newItem];
        return newArray;
      };
      
      // Function to delete an item from the array
      const deleteItemFromArray = (array, index) => {
        const newArray = [...array];
        newArray.splice(index, 1);
        return newArray;
      };

      
      const handleAddItem = (selectedDate, selectedCategory,selectedcategoryName,selectedMealId,selectedMealName,type) => {
        if (!selectedDate || !selectedCategory || !selectedcategoryName || !selectedMealId || !selectedMealName) {
          // Handle the case where one or more variables are empty or undefined
          seterror("One or more variables are empty or undefined");
        } else {
          const itemIndex = items.findIndex(item => item.item_id === selectedMealId);
          if (itemIndex === -1) {
            // Item with selectedMealId not found in array, add a new item
            const newItems = addItemToArray(items, selectedDate, selectedCategory,selectedcategoryName,selectedMealId,selectedMealName,type);
            const newsnacks = newItems.filter(item =>  item.type === 'snack')
            setItems(newItems);
            setSnacks(newsnacks)
          } else {
            // Item with selectedMealId found in array, update the existing item
         
          }
       
        }
      };
    
      const handleDeleteItem = (index) => {
        const newItems = deleteItemFromArray(items, index);
        setItems(newItems);
      };
      // end array of chef meals



      const saveChefMeals = ()=>{
        const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
            if(items.length<4||items.filter(item =>  item.type === 'snack').length<2){
              alert("please add 4 meals at least and 2 snacks")
              console.log(items.filter(item =>  item.category_chef_id === 4))
            }
            else{
              setloading(1)
              console.log(snacks.sort(function(a, b) {
                return a.id - b.id;
                }))
              axios({
                method: 'post',
                url: api+'getSubscriptionLive',
                headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '
                },
                data:{
                   data:items,
                  meals:items.filter(item =>  item.type === 'meal').sort(function(a, b) {
                    return a.id - b.id;
                    }),
                  snacks:snacks.sort(function(a, b) {
                    return a.id - b.id;
                    }),
                  date:formattedDate
                
                }
              })
              .then(response => {
                console.log("yes")
                console.log(response.data);
               alert("done")
               setloading(0)
              })
              .catch(error => {
                console.log(error);
                alert("error")
                setloading(0)

      
              });
            }
   
      }

      const[getaxios]=useaxios()
      useEffect(() => {
       getaxios('ChefMealsCategory').then(data => {
        setcategories(data)
        setFilteredCategories(data)
        console.log(data)
       }
        ).catch(error => console.error(error));

        // getaxios('item').then(data => {
        //   setmeals(data)
        //   setFilteredMeal(data)
        //   console.log(data)
        //  }
        //   ).catch(error => console.error(error));

      }, [])

     
    return(
      loading==1?
      <Text>loading ...</Text>
      :
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={2}/>
      <NavBar3 navigation={navigation} nav2active={3}/>
   
      <View style={styles.container2}>
        <Text >Add  meals to not select page</Text>

         {/*Date  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Date</Text>

   {/* Select the date*/}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Select the date</Text>
<Picker
        selectedValue={year}
        onValueChange={(itemValue) => setYear(itemValue)}
        style={styles.TextInput}

      >
        {years.map((y) => <Picker.Item key={y} label={`${y}`} value={`${y}`} />)}
      </Picker>
      <Picker
        selectedValue={month}
        onValueChange={(itemValue) => setMonth(itemValue)}
        style={styles.TextInput}

      >
        {months.map((m) => <Picker.Item key={m} label={`${m}`} value={`${m}`} />)}
      </Picker>
      <Picker
        selectedValue={day}
        onValueChange={(itemValue) => {
          setDay(itemValue)
        }}
        style={styles.TextInput}

      >
        {days.map((d) => <Picker.Item key={d} label={`${d}`} value={`${d}`} />)}
      </Picker>
</View>
 {/*end of Select the date*/}
 <TouchableOpacity 
 onPress={()=>handleDayChange(day)}
 style={styles.Button} >
<Image 
    source={dateicon}  
    style={{width: 24, height: 24 }}
/>
  <Text>select date</Text>
</TouchableOpacity>
</View>
{/* end of Date */}


 {/*meals  */}
 <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>meals</Text>
{/* 2 row input */}
<View style={styles.ViewInput2Row}>

    {/* <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>meal Category</Text>
<TouchableOpacity onPress={() => setPickerVisible(true)}
                    style={styles.TextInput}

            >
        
          <Text
          style={{textAlign:"left"}}
          >{ selectedcategoryName  +" "+ selectedCategory || 'Select a category'}</Text>
        
      </TouchableOpacity>
</View>


     <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>meal </Text>
<TouchableOpacity onPress={() => setMealPickerVisible(true)}
                    style={styles.TextInput}

            >
        
          <Text
          style={{textAlign:"left"}}
          >{ selectedMealName  || 'Select a meal'}</Text>
        
      </TouchableOpacity>
</View> */}

 <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                {/* <TouchableOpacity style={styles.Button}        onPress={()=>
                handleAddItem(
                  `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
                  
                 , selectedCategory,selectedcategoryName
                  ,selectedMealId,selectedMealName,type
                  )}
>
            
                    <Text style={styles.saveBtnText}>اضف الى الجدول </Text>
                </TouchableOpacity> */}

                <TouchableOpacity style={styles.Button}        onPress={()=>window.print()}
>
            
                    <Text style={styles.saveBtnText}>print</Text>
                </TouchableOpacity>

            </View>

</View>
{/*end of 2 row input */}

</View>
{/* end of meals */}



      {/* select meals */}

         
      <Modal visible={isMealPickerVisible} animationType="slide">
        <View style={searchPicker.modalContainer}>
        <TouchableOpacity onPress={() => setMealPickerVisible(false)}
        style={searchPicker.closeButton}
        >
              <Text style={searchPicker.closeButtonText}>Close</Text>
            </TouchableOpacity>


          <View style={styles.searchContainer}>
            <TextInput
              style={searchPicker.searchInput}
              onChangeText={handleSearchmeal}
              value={mealsSearchQuery}
              placeholder="Search meal"
            />
          </View>


          <ScrollView>

          <View style={styles.pickerContainer}>
            <View style={styles.picker}>
              {filteredMeal.map((meal, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => handleMealValueChange(meal.item_id,meal.itemNameAR)}
                  style={searchPicker.pickerItem}
                >
                  <Text style={{color:"black",textAlign:"left"}}>{meal.itemNameAR} </Text>
                  

                </TouchableOpacity>
              ))}
            </View>
          </View>
          </ScrollView>

    

        </View>
      </Modal>
      {/* end of meal select */}


                 {/* start of category select */}
     
      <Modal visible={isPickerVisible} animationType="slide">
        <View style={searchPicker.modalContainer}>
        <TouchableOpacity onPress={() => setPickerVisible(false)}
        style={searchPicker.closeButton}
        >
              <Text style={searchPicker.closeButtonText}>Close</Text>
            </TouchableOpacity>


          <View style={styles.searchContainer}>
            <TextInput
              style={searchPicker.searchInput}
              onChangeText={handleSearch}
              value={searchQuery}
              placeholder="Search categories"
            />
          </View>


          <ScrollView>

          <View style={styles.pickerContainer}>
            <View style={styles.picker}>
              {filteredCategories.map((category, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => handleValueChange(category.id, category.categoryNameAR,category.type)}
                  style={searchPicker.pickerItem}
                >
                  <Text style={{color:"black",textAlign:"left"}}>{category.type} : {category.categoryNameAR} </Text>
                  

                </TouchableOpacity>
              ))}
            </View>
          </View>
          </ScrollView>

        </View>
      </Modal>
      {/* end of category select */}











{/* add btn */}



  {/* end of add btn */}


  {/* {
    items&&items.map((item,index) => (
        <TouchableOpacity
        onPress={()=>handleDeleteItem(index)}
        key={index}
        >
            <Text>
              {item.date} + {item.category_chef_id} + {item.categoryNameAR}
            
            {item.item_id} {item.itemNameAR}
            </Text>
        </TouchableOpacity>
    ))
} */}
<View style={{marginVertical:15,marginHorizontal:10}}>
<View style={styles.tableHeaderView}>
        <Text  style={styles.cellHeader}>
            Date
          </Text>
          
          <Text  style={styles.cellHeader}>
            meal
          </Text>
          <Text  style={styles.cellHeader}>
            category
          </Text>

          <Text  style={styles.cellHeader}>
            type
          </Text>

          <Text  style={styles.cellHeader}>
            meal id
          </Text>
   
      </View>
{
    items&&items.map((item,index) => (
        <TouchableOpacity
        // onPress={()=>handleDeleteItem(index)}
        style={styles.row}
        key={index}
        >
            <Text style={styles.cell}>
              {item.date} 
            </Text>
            <Text style={styles.cell}>
            
            {lang=="ar"?item.itemNameAR:item.itemNameEN}

            </Text>
            <Text style={styles.cell}>
            
            {lang=="ar"?item.categoryNameAR:item.categoryNameEN}

            </Text>
            <Text style={styles.cell}>
            {item.type}
            </Text>
            <Text style={styles.cell}>
            {item.item_id}
            </Text>
        </TouchableOpacity>
    ))
}
</View>



        </View>

            {/* add btn */}

<View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}        onPress={()=>saveChefMeals()}
>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                    <Text style={styles.saveBtnText}>  حفظ الوجبات  </Text>
                </TouchableOpacity>

            </View>

  {/* end of add btn */}
      

           
</View>
    )
}

export default AddMealsNotSelect;
