import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,Picker,Button,Switch,Modal,ScrollView
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';
import DateTimePicker from 'react-datetime-picker';
import {searchPicker} from '../../../assets/stylesDark/searchPicker'

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/stylesDark/datePickerStyles'
import axios from 'axios';
import { api } from '../../../assets/assets';

const AddSubcribe =({navigation})=>{
  const handelDate = (date) => {
     const year = date.getFullYear();
     const month = String(date.getMonth() + 1).padStart(2, "0");
     const day = String(date.getDate()).padStart(2, "0");
     return(`${year}-${month}-${day}`)
   }
    const nav=useSelector(state =>state.navbar.nav2Active);

    
    const [error, setError] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [userId, setUserId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [packageId, setPackageId] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [snacks, setSnacks] = useState(1);
    const [protein, setProtein] = useState('');
    const [meals, setMeals] = useState(1);
    const [carbs, setCarbs] = useState('');
    const [calories, setCalories] = useState('1200');
    const [amount, setAmount] = useState('');
    const [trackId, setTrackId] = useState(0);
    const [paid, setPaid] = useState('yes');
    const [paymentId, setPaymentId] = useState(2);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [deliveryTimeId, setDeliveryTimeId] = useState(1);
    const [selectedDeliveryTime, setSelectedDeliveryTime] = useState(null);
    const [notes, setNotes] = useState('no notes');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [pauseStartDate, setPauseStartDate] = useState(null);
    const [pauseEndDate, setPauseEndDate] = useState(null);
    const[discountCode,setDiscountCode]=useState("no code");
    const [users,setusers] = useState([{ "id": 1, "name": "loading...", "phone": "0" }]);

//search user 
    const [usersSearchQuery, setUserSearchQuery] = useState('');
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selecteduserName, setSelectedUserName] = useState('');
    const [isUserPickerVisible, setUserPickerVisible] = useState(false);
    const [filteredUser, setFilteredUser] = useState(users);
    const handleSearchUser= (query) => {
      const filtered = users.filter((user) =>
        user.phone.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredUser(filtered);
      setUserSearchQuery(query);
    };
    const handleUserValueChange = (value,name) => {
      setSelectedUserId(value);
      setUserPickerVisible(false);
      setSelectedUserName(name)
    };

    // End search user 





    // Define the user and package data
  
    const [packages,setpackages] =useState([    { "id": 1, "packageNameAR": "loading..." }]);
  
    const payments = [    { "id": 1, "paymentname": "cash" },{ "id": 2, "paymentname": "myfatoorah" }  ];
  
    const deliveryTimes = [    { "id": 1, "time": "7 am to 1 pm" }  ];
  
    // Modal state and functions
    const [modalVisible, setModalVisible] = useState(false);
    const [searchPhone, setSearchPhone] = useState('');
  
    const handleUserSelect = () => {
      setSelectedUser(users.find(user => user.id === userId));
      setModalVisible(false);
    };
  
    const handleSearchPhone = (text) => {
      setSearchPhone(text);
    };

  
    const filteredUsers = users.filter(user => user.phone.includes(searchPhone));
  
    // Package picker items
    const packagePickerItems = packages.map((pkg) => (
      <Picker.Item key={pkg.id} label={pkg.packageNameAR} value={pkg.id} />
    ));
  
    // Payment picker items
    const paymentPickerItems = payments.map((payment) => (
      <Picker.Item key={payment.id} label={payment.paymentname} value={payment.id} />
    ));
  
    // Delivery time picker items
    const deliveryTimePickerItems = deliveryTimes.map((time) => (
      <Picker.Item key={time.id} label={time.time} value={time.id} />
    ));

    const [deliveryTime, setDeliveryTime] = useState(null);
const [pauseStartDateSelected, setPauseStartDateSelected] = useState(false);
const [pauseEndDateSelected, setPauseEndDateSelected] = useState(false);


const [days, setDays] = useState({
       
  Saturday: 'yes',
  Sunday: 'yes',
   Monday: 'yes',
  Tuesday: 'yes',
  Wednesday: 'yes',
  Thursday: 'yes',
  Friday: 'yes'
});

const [daysInWeek, setdaysInWeek] = useState();
const [subscribeDays, setsubscribeDays] = useState();

const [selectedDays, setSelectedDays] = useState([]);
const [errorMessage, setErrorMessage] = useState('');

const handleDaySelection = (day) => {
  if (selectedDays.includes(day)) {
    setSelectedDays(selectedDays.filter((selectedDay) => selectedDay !== day));
    setDays({ ...days, [day]: 'yes' });
  } else {
      setSelectedDays([...selectedDays, day]);
      setDays({ ...days, [day]: 'no' });
 
  }
};

const handleSubmit = () => {

  if (
    selectedUserId !== '' &&
    packageId.trim() !== '' &&
    discountCode.trim() !== '' &&
    snacks !== '' &&
    meals !== '' &&
    protein !== '' &&
    carbs !== '' &&
    amount !== '' &&
    paid !== '' &&
    paymentId !== '' &&
    startDate !== '' && endDate !== '' && deliveryTimeId !== '' && notes !== '' 
    ){
      let data={
        user_id:selectedUserId,
        package_id:packageId,
        snacks:snacks,
        protein:protein,
        meals:meals,
        carbs:carbs,
        Calories:calories,
        code:discountCode,
        amount:amount,
        trackid:trackId,
        paid:paid,
        payment_id:paymentId,
        deleviryTime:deliveryTimeId,
        notes:notes,
        startDate:handelDate(startDate),
        endDate:handelDate(endDate),
        puseStartDate:pauseStartDate!=null?handelDate(pauseStartDate):pauseStartDate,
        puseEndDate:pauseEndDate!=null?handelDate(pauseEndDate):pauseStartDate,


        daysInWeek:daysInWeek,
              subscribeDays:subscribeDays,
              Monday:days.Monday,
              Tuesday:days.Tuesday,
              Wednesday:days.Wednesday,
              Thursday:days.Thursday,
              Friday:days.Friday,
              Saturday:days.Saturday,
              Sunday:days.Sunday
      }
      console.log(data)
      axios({
        method: "post",
        url: api+"Track",
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
        data:{company_id:1}
    })
    .then((response) => {
         console.log(response.data);
         data['trackid']=response.data['id']
         axios({
          method: "post",
          url: api+"Subscription",
          headers: { 
              'Accept': 'application/json',
              'Authorization': 'Bearer '
          },
          data:data
      })
      .then((response) => {
           console.log(response.data);
           alert("done")
setError("done")
      })
      .catch((error) => {
          console.log(error);
          setError("error")
          alert("error")

      });

    })
    .catch((error) => {
        console.log(error);
    });
  
    }
    else{
      alert('Invalid subscribe:')
    }
  // code to handle form submission
};
useEffect(() => {
  axios({
    method: "get",
    url: api+"User",
    headers: { 
        'Accept': 'application/json',
        'Authorization': 'Bearer '
    }
})
.then((response) => {
     console.log(response.data);
     setusers(response.data);
     setFilteredUser(response.data)
})
.catch((error) => {
    console.log(error);
});
axios({
  method: "get",
  url: api+"Package",
  headers: { 
      'Accept': 'application/json',
      'Authorization': 'Bearer '
  }
})
.then((response) => {
   console.log(response.data);
   setpackages(response.data);
})
.catch((error) => {
  console.log(error);
});
}, [])
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={2}/>
      <NavBar3 navigation={navigation} nav2active={1}/>



        
      <View style={styles.container2}>
        <Text >Add subscribe page</Text>

         

{/* genral info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>genral info</Text>


 {/* input user name */}

 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>User :  </Text>
<TouchableOpacity onPress={() => setUserPickerVisible(true)}
                    style={styles.TextInput}

            >
        
          <Text
          style={{textAlign:"left"}}
          >{ selecteduserName  || 'Select user'}</Text>
        
      </TouchableOpacity>
</View>
<Modal visible={isUserPickerVisible} animationType="slide">
        <View style={searchPicker.modalContainer}>
        <TouchableOpacity onPress={() => setUserPickerVisible(false)}
        style={searchPicker.closeButton}
        >
              <Text style={searchPicker.closeButtonText}>Close</Text>
            </TouchableOpacity>


          <View style={styles.searchContainer}>
            <TextInput
              style={searchPicker.searchInput}
              onChangeText={handleSearchUser}
              value={usersSearchQuery}
              placeholder="Search user"
            />
          </View>


          <ScrollView>

          <View style={styles.pickerContainer}>
            <View style={styles.picker}>
              {filteredUser.map((user, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => handleUserValueChange(user.id,user.name)}
                  style={searchPicker.pickerItem}
                >
                  <Text style={{color:"black",textAlign:"left"}}>{user.name} {user.phone}</Text>
                  

                </TouchableOpacity>
              ))}
            </View>
          </View>
          </ScrollView>

    

        </View>
      </Modal>

  {/* input Package name */}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Package:</Text>
<Picker
selectedValue={packageId}
onValueChange={(value) => setPackageId(value)}
style={styles.TextInput}

>
<Picker.Item label="Select package" value={null} />
{packages.map((pkg) => (
<Picker.Item key={pkg.id} label={pkg.packageNameAR} value={pkg.id} />
))}
</Picker>
</View>
  {/* end of input Package name */}

</View>
  {/* end of genral info */}


  {/* days of subscribe*/}

  <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>days </Text>
<Text >days اختر ايام العطل فقط</Text>


<View style={{flexDirection:"row"}}>

{Object.keys(days).map((day) => (
        <TouchableOpacity
          key={day}
          onPress={() => handleDaySelection(day)}
          style={{ flexDirection: 'row', alignItems: 'center' ,margin:10}}
        >
          <View
            style={{
              width: 24,
              height: 24,
              borderRadius: 12,
              borderWidth: 2,
              marginRight: 8,
              backgroundColor: days[day] === 'no' ? "black" : 'transparent',
              borderColor: colors.darkburble,
            }}
          />
                 <Text style={styles.daysBtnText}> 
                     {day} -
                     {
                     day=="Saturday"?"السبت":
                     day=="Sunday"?"الأحد":
                     day=="Monday"?"الأثنين":
                     day=="Tuesday"?"الثلاثاء":
                     day=="Wednesday"?"الاربعاء":
                     day=="Thursday"?"الخميس":
                     day=="Friday"?"الجمعة":null
                     
                     
                     }
                  </Text>
        </TouchableOpacity>
      ))}
      </View>
      <View style={{flexDirection:"row"}}>
          {/* input daysInWeek  */}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>days In Week :</Text>
<TextInput
value={daysInWeek}
onChangeText={(text) => setdaysInWeek(text)}
style={styles.TextInput}
/>
</View>
  {/* end of daysInWeek  */}

         {/* input subscribeDays  */}
         <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>total subscribe Days :</Text>
<TextInput
value={subscribeDays}
onChangeText={(text) => setsubscribeDays(text)}
style={styles.TextInput}
/>
</View>
  {/* end of daysInWeek  */}
</View>
</View>
  {/* end days of subscribe*/}



{/* Discount Code  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Discount Code</Text>

  {/* input Discount Code */}
  <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Discount Code :</Text>
<TextInput
value={discountCode}
onChangeText={(text) => setDiscountCode(text)}
style={styles.TextInput}
/>
</View>
  {/* end of input protien  */}

</View>
{/* Discount Code  */}

{/* meals info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>meals info</Text>
      {/* 4 row input */}
<View style={{flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>

{/* input Snacks */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Snacks:</Text>
<Picker
selectedValue={snacks}
onValueChange={(value) => setSnacks(value)}
style={styles.TextInput}

>
{[...Array(7).keys()].map((snack) => (
<Picker.Item key={snack} label={String(snack )} value={snack } />
))}
</Picker>

</View>
  {/* end of input Snacks  */}

    {/* input meals */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>meals:</Text>
<Picker
selectedValue={meals}
onValueChange={(value) => setMeals(value)}
style={styles.TextInput}
>
{[...Array(6).keys()].map((meal) => (
<Picker.Item key={meal} label={String(meal + 1)} value={meal + 1} />
))}
</Picker>
</View>
    {/* end of input meals */}


  {/* input Protein */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Protein:</Text>
<TextInput
keyboardType="numeric"
value={protein}
onChangeText={(text) => setProtein(text)}
style={styles.TextInput}
/>
</View>
  {/* end of input protien  */}

    {/* input Carbs */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Carbs:</Text>
<TextInput
keyboardType="numeric"
value={carbs}
onChangeText={(text) => setCarbs(text)}
style={styles.TextInput}
/>
</View>
  {/* end of input Carbs  */}

</View>      {/* end of 4 row  */}


   {/* input Calories */}
   <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Calories:</Text>
<TextInput
keyboardType="numeric"
value={calories}
onChangeText={(text) => setCalories(text)}
style={styles.TextInput}
/>
</View>
  {/* end of input Calories  */}


</View>
{/*end of meals info  */}


{/* payment info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>payment info</Text>


      {/* 4 row input */}
<View style={{flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>


{/* input Amount */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Amount:</Text>
<TextInput
keyboardType="numeric"
value={amount}
onChangeText={(text) => setAmount(text)}
style={styles.TextInput}
/>
</View>
{/* end of input Amount */}


{/* input Track */}
{/* <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Track ID:</Text>
<TextInput
keyboardType="numeric"
value={trackId}
onChangeText={(text) => setTrackId(text)}
style={styles.TextInput}

/>
</View> */}
{/* end of input Track */}


{/* input Paid */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Paid:</Text>
<Picker
selectedValue={paid}
onValueChange={(value) => setPaid(value)}
style={styles.TextInput}
>
<Picker.Item label="Yes" value="yes" />
<Picker.Item label="No" value="no" />
</Picker>
</View>
{/* end of input Paid */}


{/* input Payment method */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Payment method:</Text>
<Picker
selectedValue={paymentId}
onValueChange={(value) => setPaymentId(value)}
style={styles.TextInput}

>
{payments.map((payment) => (
<Picker.Item key={payment.id} label={payment.paymentname} value={payment.id} />
))}
</Picker>
</View>
{/* end of input Payment method */}



</View> {/* end of 4 row  */}
</View>
{/* end of payment info  */}

  

  {/* dates info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>dates info</Text>



      {/* 2 row input */}
      <View style={styles.ViewInput2Row}>
      <View style={{width:350}}><Text style={styles.inputHeaderText}>Start Date:</Text></View>

            <View style={{width:350}}><Text style={styles.inputHeaderText}>End Date:</Text></View>

      </View>

<View style={styles.ViewInput2Row}>

  {/* input Start Date */}
<View style={styles.inputView}>
<View style={{width:350,height:350}}>

<DateTimePicker
    value={startDate}
    onChange={(date) => setStartDate(date)}
    format="dd/MM/yyyy HH:mm"
    minDate={new Date()}
    style={styles.TextInput}

    />
</View></View>
  {/* end of input Start Date */}


   {/* input End Date */}
<View style={styles.inputView}>
<View style={{width:200,height:350}}>
<DateTimePicker
        value={endDate}
        onChange={(date) => setEndDate(date)}
        format="dd/MM/yyyy HH:mm"
        minDate={startDate || new Date()}
        />
</View>

</View>
  {/* end of input End Date */}






</View>
      {/* end of 2 row input */}

         {/* 2 row input */}
<View style={styles.ViewInput2Row}>
     {/* input Pause Start Date */}
     {/* <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Pause Start Date:</Text>
<View style={{ flexDirection: "row" }}>
        <Picker
          selectedValue={pauseStartDateSelected}
          onValueChange={(value) => setPauseStartDateSelected(value)}
          style={styles.TextInput}
        >
          <Picker.Item label="No Pause" value={null} />
          <Picker.Item label="Set Pause Start Date" value={"set"} />
        </Picker>
        {pauseStartDateSelected === "set" && (
          <DateTimePicker
            value={pauseStartDate}
            onChange={(date) => setPauseStartDate(date)}
            format="dd/MM/yyyy HH:mm"
            minDate={startDate || new Date()}
            maxDate={endDate}
            style={styles.TextInput}
          />
        )}
      </View>
</View> */}
     {/*end of  input Pause Start Date */}


      {/* input Pause End Date */}
{/* <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Pause End Date:</Text>
<View >
        <Picker
          selectedValue={pauseEndDateSelected}
          onValueChange={(value) => setPauseEndDateSelected(value)}
          style={styles.TextInput}

        >

          <Picker.Item label="No Pause" value={null} />
          <Picker.Item label="Set Pause End Date" value={"set"}
          />
        </Picker>
        {pauseEndDateSelected === "set" && (

          <DateTimePicker
            value={pauseEndDate}
            onChange={(date) => setPauseEndDate(date)}
            format="dd/MM/yyyy HH:mm"
            minDate={pauseStartDate || startDate}
            maxDate={endDate}
            style={datePickerStyles.container}

          />
          
        )}
      </View>
</View> */}
     {/*end of  input Pause End Date */}
</View>
   {/* end of 2 row input */}

</View>
  {/* end of dates info  */}




  {/*Delivery time  */}
  <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>Delivery time</Text>

   {/* input Delivery time */}
   <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Delivery time:</Text>
<Picker
    selectedValue={deliveryTimeId}
    onValueChange={(value) => setDeliveryTimeId(value)}
    style={styles.TextInput}

  >
    <Picker.Item label="Select delivery time" value={null} />
    {deliveryTimes.map((time) => (
      <Picker.Item label={time.time} value={time.time} key={time.id} />
    ))}
  </Picker>
</View>
  {/*end of */}

</View>
  {/* end of Delivery time  */}


  {/* notes  */}
  <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>notes</Text>
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>notes :</Text>
<TextInput
    value={notes}
    onChangeText={(text) => setNotes(text)}
    style={styles.TextInput}
  />
  </View>

</View>
  {/* notes */}





    
  
    
  

 



 
 











            

         






        
        </View>
        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}   
                onPress={()=>handleSubmit()}     
>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                    <Text style={styles.saveBtnText}>add</Text>
                </TouchableOpacity>

            </View>

           
</View>
    )
}

export default AddSubcribe;
