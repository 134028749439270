import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,ScrollView,FlatList,StyleSheet,Picker,Button
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import axios from 'axios';
import {styles} from '../../../assets/styleSAP/formaInputSap'

import useTable from "../../../assets/hooks/useTable";
import { api } from '../../../assets/assets';
import TableShow from '../../table/TableShow';

import dateicon from '../../../assets/iconpng/newicons/date.png'




const Deleviry = ({navigation}) => {
    
  const apilink='getSubscriptionLiveAddress';
  const pagetoEdit='EditItem'

  const [data,setData]=useState([])
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [headerColumns, setHeaderColumns] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const hide=[{header :"created_at"},{header :"updated_at"}
  // ,{header :"wieght"},{header :"hight"},{header :"gender"},{header :"birthday"}
]

  const[loading,setloading]=useState(0)

  const [headerColumnsOrginal, setheaderColumnsOrginal] = useState([]);

const formattedDate=()=>{
  return  `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
}
const refresh =()=>{
    axios({
        method: "get",
        url: api+apilink,
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
        data:{date:formattedDate}

    })
    .then((response) => {
         console.log(response.data);
         setTableData(response.data);
         setData(response.data);
      setHeaderColumns(Object.keys(response.data[0] || {}));
      setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
      setloading(1)
    })
    .catch((error) => {
        console.log(error);
    });
}

  // Update the table data when the incoming data prop changes
  useEffect(() => {

    
  }, []);

  const today = new Date();

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth()+1);
  const [day, setDay] = useState(today.getDate());
  const [selectedDate, setSelectedDate] = useState('');

  const years = Array.from({length: 10}, (v, i) => 2023 +i);
  const months = Array.from({length: 12}, (v, i) => i + 1);
  const days = Array.from({length: 31}, (v, i) => i + 1);

  const updateSelectedDate = () => {
   
    setSelectedDate(formattedDate);
    axios({
        method: "post",
        url: api+apilink,
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
        data:{date:formattedDate()}
    })
    .then((response) => {
         console.log(response.data);
         setTableData(response.data);
         setData(response.data);
      setHeaderColumns(Object.keys(response.data[0] || {}));
      setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
      setloading(1)
  
    })
    .catch((error) => {
        console.log(error);
    });
  };

 
  const[TableHandleSearch,handleHeaderClick,handlesortedData,handleDownload]=useTable(data);

  
  
  return (
    <SafeAreaView style={[styles.container,{minHeight:1080}]}>
    <NavBar navigation={navigation}/>
    <NavBar2 navigation={navigation} nav2active={4}/>
    <NavBar3 navigation={navigation} nav2active={1}/>




      
    <View style={styles.container2}>
        <Text >Deleviry page</Text>

         {/* date  */}
<View style={styles.formaView}>
<View style={styles.formaViewHeader}>
<Image 
    source={dateicon}  
    style={{width: 24, height: 24 }}
/>
  <Text>Date</Text></View>
   {/* input chose date */}
   <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>chose date</Text>
<Picker
        selectedValue={year}
        onValueChange={(itemValue) => setYear(itemValue)}
        style={styles.TextInput}

      >
        {years.map((y) => <Picker.Item key={y} label={`${y}`} value={`${y}`} />)}
      </Picker>
      <Picker
        selectedValue={month}
        onValueChange={(itemValue) => setMonth(itemValue)}
        style={styles.TextInput}

      >
        {months.map((m) => <Picker.Item key={m} label={`${m}`} value={`${m}`} />)}
      </Picker>
      <Picker
        selectedValue={day}
        onValueChange={(itemValue) => setDay(itemValue)}
        style={styles.TextInput}

      >
        {days.map((d) => <Picker.Item key={d} label={`${d}`} value={`${d}`} />)}
      </Picker>

</View>
<TouchableOpacity onPress={updateSelectedDate} style={styles.Button} >
<Image 
    source={dateicon}  
    style={{width: 24, height: 24 }}
/>
  <Text>select date</Text>
</TouchableOpacity>

</View>
{/* end of date  */}

        <View>
    
      <Text>{selectedDate}</Text>
    </View>

        {loading==0?null: <TableShow
        
     pagetoEdit1={pagetoEdit}
     data1={data}
     tableData1={tableData}
     headerColumns1={headerColumns}
     headerColumnsOrginal1={headerColumnsOrginal}
     hide={hide}
     navigation={navigation}/>}

  </View>
  
  
  </SafeAreaView>
  );
  };
  
  

export default Deleviry;