import React from 'react'
import {TouchableOpacity} from 'react-native'
import { PDFDownloadLink, Page, Text, View, Document,Font,StyleSheet } from '@react-pdf/renderer';
import {styles} from '../../../assets/styleSAP/formaInputSap'
import '../../../../index.css'

const P =({items,dateofmeals})=>{
     const Almarai_Regular = require('../../../assets/fonts/Almarai/Almarai-Regular.ttf');
     Font.register({ family: 'Almarai-Regular', src: Almarai_Regular });

    function getDateAsString(dateofmeals) {
      const today = new Date(dateofmeals);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const dateString = `${year}-${month}-${day}`;
      return dateString;
    }
    const stylesTitle = StyleSheet.create({
        title: {
          fontFamily:"Almarai-Regular",
          fontSize:20
        },

    formaView:{
      borderWidth:0.5,
      marginBottom:55
   
  },
      })
    //   const MyPDF = () => {
    //     return (
    //       <Document>
    //         <Page size="A4">
    //           <View>
    //             <Text style={styles.title}>Hello, World!</Text>
    //           </View>
    //         </Page>
    //       </Document>
    //     );
    //   };

      const MyPDF = () => {
        return (
            <Document>

            {items.map((item, index) => (
              //<Page size={{ width: 200, height: 200 }}
             <Page size={{ width: 1080}} >

<View style={{width:'100%' ,borderWidth:0.2,borderColor:"black",padding:2}}>
  <View style={{margin:5}} >
  <Text style={{alignSelf:"center",fontSize:22,fontWeight:"bold"}}>Box-{item.id} |Zone{item.zoneId}|d:{item.deleviryTimeId}
 </Text> 
  </View>
  <Text style={{fontSize:20,fontWeight:"bold",margin:5}}>date : {dateofmeals}|phone :{item.phone}
 </Text>
 

 <Text style={{fontSize:20,fontWeight:"bold",fontFamily:"Almarai-Regular",margin:5}}> Name : {item.name}
 </Text> 

 <Text style={{fontSize:20,fontWeight:"bold",fontFamily:"Almarai-Regular",margin:5}}> notes : {item.notes}
 </Text> 
    
 <Text style={{fontSize:20,fontWeight:"bold",margin:5}}>carb : {item.carbs} | protein : {item.protein} | 
 meals : {item.meals} |
 snacks : {item.snacks}
 </Text> 
 <Text style={{fontSize:22,fontWeight:"bold",fontFamily:"Almarai-Regular",margin:5}}>
 Meal :
 </Text>
 {item.mealsChef.map((meal, index) => (
   <Text style={{fontSize:20,fontWeight:"bold",fontFamily:"Almarai-Regular",margin:5}}>
    * {meal.itemNameAR} | {meal.itemNameEN}
    </Text>
                  )
                  )}
                  

                  <Text style={{fontSize:22,fontWeight:"bold",fontFamily:"Almarai-Regular",margin:5}}> 
                  Snack : 
                  </Text>
  {item.snacksCehf.map((snack, index) => (
   <Text style={{fontSize:20,fontWeight:"bold",fontFamily:"Almarai-Regular",margin:5}}>

* {snack.itemNameAR}|{snack.itemNameEN}
</Text>


                                    

                  )
                  )}
                

                  <Text style={{fontSize:20,fontWeight:"bold",fontFamily:"Almarai-Regular",margin:5}}> {item.area_nameAR} {item.area_nameEN} |
                  B:{item.block}| S:{item.street}| J:{item.jadah}| H:{item.home}
                  | Floor:{item.floor}| Flat:{item.flat}
 </Text> 

















 </View>   
 
              </Page> 
            ))}
          </Document>
        );
      };
    return(
        <View>
           
            <PDFDownloadLink document={<MyPDF />} fileName="mypdf.pdf"
            
        >
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Print PDF'
        }
      </PDFDownloadLink>

        </View>
    )
}

export default P;