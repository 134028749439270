import React from 'react'
import {TouchableOpacity} from 'react-native'
import { PDFDownloadLink, Page, Text, View, Document,Font,StyleSheet } from '@react-pdf/renderer';
import {styles} from '../../../assets/styleSAP/formaInputSap'
import '../../../../index.css'

const PrintBox =({
  mealnamear,
  mealnameen,
datestring,
subscriptions

})=>{
     const Almarai_Regular = require('../../../assets/fonts/Almarai/Almarai-Regular.ttf');
     Font.register({ family: 'Almarai-Regular', src: Almarai_Regular });

     const addDays = (startDate,num) => {
      let currentDate = new Date(startDate);
      currentDate.setDate(currentDate.getDate() + num)
      let year = currentDate.getFullYear();
      let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      let day = currentDate.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`
     }    
    const stylesTitle = StyleSheet.create({
        title: {
          fontFamily:"Almarai-Regular",
          fontSize:16
        },

    formaView:{
      borderWidth:0.5,
      marginBottom:55
   
  },
      })


      const MyPDF = () => {
        return (
            <Document>

            {subscriptions.map((item, index) => (
              //<Page size={{ width: 200, height: 200 }}
             <Page size={{ width: 58, height: 38 }} >

<View style={{width:58,height:38 ,borderWidth:0.2,borderColor:"black",padding:2}}>
  <View >
  <Text style={{fontSize:3,fontWeight:"bold"}}>Box-{item.subscribe_id} 
 </Text> 
  <Text style={{fontSize:3,fontWeight:"bold"}}>S : {datestring}  | E : {addDays(datestring,3)}
 </Text> 

  </View>
  <Text style={{fontSize:3,fontWeight:"bold",fontFamily:"Almarai-Regular",textAlign:"right"
}}>{mealnamear}
 </Text> 
 <Text style={{fontSize:3,fontWeight:"bold",fontFamily:"Almarai-Regular"}}>{mealnameen}
 </Text> 
 <Text style={{fontSize:3,fontWeight:"bold",fontFamily:"Almarai-Regular",textAlign:"right"}}>{item.notes}
 </Text> 
  
    
 










 </View>   
 
              </Page> 
            ))}
          </Document>
        );
      };
    return(
        <View>
           
            <PDFDownloadLink document={<MyPDF />} fileName="mypdf.pdf"
            
        >
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Print PDF'
        }
      </PDFDownloadLink>

        </View>
    )
}

export default PrintBox;